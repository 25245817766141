if (!global._babelPolyfill) {
    require('babel-polyfill');
}

import $ from 'jquery';
import {__, i18n} from './i18nConfig';

window.jQuery = $;
window.$ = $;

export numeral from 'numeral';
export * from 'bignumber.js';
export * from 'chart.js';
export * from './lib/common';
export * from './lib/api';
export * from './lib/otex-api';
export * from './lib/otex-common';
export Otex from './lib/Otex';
export WAValidator from 'multicoin-address-validator';
export axios from 'axios';
export {
    i18n,
    __,
};

import moment from 'moment'
import 'moment-timezone'
moment.tz.setDefault('Asia/Seoul')
export {
    moment
}


export const errorMessage = {};
errorMessage["REMAINDER_QUANTITY_INSUFFICIENT"] = __("남은 수량이 부족합니다.");
errorMessage["MINIMUM_TRADABLE_QUANTITY_INSUFFICIENT"] = __("최소 수량보다 신청 수량이 작습니다.");
errorMessage["CAN_NOT_ACTION_MYSELF"] = __("자기 자신이 등록한 거래에 신청할 수 없습니다.");
errorMessage["ALREADY_CLOSED"] = __("이미 끝난 거래입니다.");
errorMessage["TRADE_APPLICATION_DOES_NOT_EXISTS"] = __("해당 등록 신청이 존재하지 않습니다.");
errorMessage["IS_NOT_ACTION_OF_USER"] = __("해당 유저가 할 수 없는 행위입니다.");
errorMessage["IS_NOT_ACTION_IN_CURRENT_STATUS"] = __("현재 상태에서 할 수 없는 행위입니다.");
errorMessage["VERIFY_PIN_FAILED"] = __("PIN 인증 오류입니다.");
errorMessage["NOT_AVAILABLE_WITHDRAWAL_QUANTITY"] = __("출금 요청 수량이 출금 가능 수량보다 큽니다.");
errorMessage["WALLET_QUANTITY_INSUFFICIENT"] = __("지갑 잔액이 부족합니다.");
errorMessage["WALLET_DOES_NOT_EXIST"] = __("해당 지갑이 존재하지 않습니다.");
errorMessage["BANK_ACCOUNT_DOES_NOT_EXIST"] = __("계좌 정보가 존재하지 않습니다.");
errorMessage["CURRENCY_NOT_SUPPORTED"] = __("가입 된 국가와 다른 법정화폐 마켓의 코인은 거래할 수 없습니다.");
errorMessage["INSUFFICIENT_FEE"] = __("수수료가 부족합니다.");
errorMessage["TRANSACTIONS_ALREADY_IN_PROCESS"] = __("이미 거래 중인 거래입니다.");
errorMessage["TIMED_OUT_TRANSACTION"] = __("제한 시간이 지난 거래입니다.");
errorMessage["NON_TRADABLE_TOKEN"] = __("거래 불가능한 토큰입니다.");

export const getBaseTransactionFeeRate = () => 0.01;
export const getBaseCurrencies = () => ['KRW', 'USD', 'CNY'];
export const getBaseTokens = () => [
    'DMT',
    'WTC', 'BIT', 'LUMP', 'TUSD', 'USDC',
    'FCC', 'TRX', 'ITDa', 'MDC', 'OTX', 'UM',
    'SVD', 'ETH', 'USDT', 'KLAY', 'KUSDT',
    'BNB', 'BUSD', 'KISC',
    'DENT', 'TNFC', 'IBOT',
    'OTEXT',
];

// let baseTokens = [];
// export const getBaseTokens = () => {
//     return baseTokens;
// }
//
// (async () => {
//     const currencies = await client.query({
//         query: gql`
//             query allCurrencies($sortField: String, $sortOrder: String) {
//                 allCurrencies(sortField: $sortField, sortOrder: $sortOrder) {
//                     id
//                     symbol
//                 }
//             }
//         `
//     }, {
//         sortField: "id",
//         sortOrder: "DESC",
//     });
//     baseTokens = currencies.data.allCurrencies.map(c => c.symbol);
// })();

export const getFixedPrice = (symbol, currency) => {
    const fixedPrice = {
        'DMT': {
            'KRW': 1,
            'USD': 0.0088,
            'CNY': 0.0059,
        },
        'MCT': {
            'KRW': 1,
            'USD': 0.0088,
            'CNY': 0.0059,
        },
        'LON': {
            'KRW': 1,
            'USD': 0.0088,
            'CNY': 0.0059,
        },
        'BBT': {
            'KRW': 120,
            'USD': 0.1,
            'CNY': 0.7,
        },
        'SRC': {
            'KRW': 120,
            'USD': 0.1,
            'CNY': 0.7,
        },
    };

    if (fixedPrice[symbol] && fixedPrice[symbol][currency]) {
        return fixedPrice[symbol][currency];
    } else {
        return 0;
    }
}

export const changeLanguage = (lang) => {
    sessionStorage.setItem('select_language', lang);

    const urlParams = new URLSearchParams(window.location.search);
    let url = window.location.href.split('?')[0];

    if (urlParams.get('domain')) {
        url += '?domain=' + urlParams.get('domain') + '&lang=' + lang;
    } else {
        url += '?lang=' + lang
    }

    location.href = url;
}

export const parseApp = (app) => {
    const price = app.tradeRegistration.price;
    const currencyCode = app.tradeRegistration.currencyCode;
    const coinType = app.tradeRegistration.coinType;


    let quantity = app.quantity;
    let priceQuantity = app.quantity;

    if (app.buyer.email === getEmail()) {
        quantity -= app.buyFee;
    }

    if (app.buyer.roles.find(r => r.name === 'ROLE_OTEX_BUYSELLER')) {
        priceQuantity -= app.buyFee;
    }

    const totalPrice = new BigNumber(price).times(priceQuantity);

    return {
        price,
        totalPrice,
        quantity,
        currencyCode,
        coinType,
    }
}

export const getCoinTypeView = (symbol) => {
    if(symbol === 'FCC') {
        return 'Efile';
    }

    return symbol;
}

// 공통표시 단위
export const convertCommonUnit = (num) => numeral(num).format('0,0.[000000000]');
