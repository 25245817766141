if (!global._babelPolyfill) {
    require('babel-polyfill');
}
import $ from 'jquery';
import {getCurrency} from "./api";

// jquery 확장함수
$.fn.extend({
    // 플랙스 fadeIn, fadeOut
    flexFadeIn(duration, callback) {
        const d = duration || 300;
        return this.each(function () {
            $(this).css({ display: 'flex', opacity: '0' }).fadeTo(d, 1, () => {
                if (callback) callback();
            });
        });
    },
    flexFadeOut(duration, callback) {
        const d = duration || 300;
        return this.each(function () {
            $(this).fadeOut(duration, () => {
                if (callback) callback();
            });
        });
    },
    // 스크롤에 따른 애니메이션
    setEffect(amount) {
        return this.each(function () {
            const $this = $(this);
            const effectType = ($(this).attr('class')).match(/effect\-(\w+)/)[1];
            const effect = `animation-${effectType}`;
            $(window).on('scroll', function (e) {
                const winTop = $(this).scrollTop();
                const screenH = $(this).height();
                const thisTop = $this.offset().top + amount;
                if (winTop + screenH > thisTop) {
                    $this.addClass(effect);
                } else {
                    $this.removeClass(effect);
                }
            });
        });
    },
});


// url 파라미터 값 반환
export function getURLParam(name) {
    // url에서 query string 부분만 떼어내 각 파라미터 그룹으로 분리
    const paramArr = location.search.substr(1).split('&');
    for (let i = 0; i < paramArr.length; i++) {
        const value = paramArr[i].split('=');
        if (value[0] == name)
        // 공백문자가 있을 경우 처리
        { return value[1].replace(/%20/g, ' '); }
    }
    return null;
}

// set cookie
export const setCookie = (name, value, day) => {
    const date = new Date()
    date.setTime(date.getTime() + day * 60 * 60 * 24 * 1000)
    document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + ';path=/'
}
// get cookie
export const getCookie = (name) => {
    const value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
    return value ? value[2] : null
}
// delete cookie
export const delCookie = (name) => {
    const date = new Date()
    document.cookie = name + "= " + "; expires=" + date.toUTCString() + "; path=/"
}

// 단순 메세지 : 자동닫힘
export function msgPop(msg) {
    const pop = $(`<div class='msgPop'>${msg}</div>`);
    pop.appendTo('body').animate({ opacity: '1' }, 300);
    setTimeout(() => {
        pop.fadeOut(300, function () { $(this).remove(); });
    }, 2000);
}
// 확인 버튼이 있는 팝업 : 콜백함수 존재하면 확인 버튼 누른 후 실행
// 아이콘 종류 : "icon-check", "icon-error", "icon-ask", "icon-info", "icon-caution"
export function alertPop(icon, msg, callback) {
    function closePop() {
        $(this).parents('.pop').animate({ top: '40%', opacity: '0' }, 300, function () {
            $(this).parents('.alertPop').fadeOut(200, function () { $(this).remove(); });
        });
        if (callback) callback();
    }
    const pop = $(`<div class='alertPop'><div class='pop'><span class='icon ${icon}'></span><p class='msg'><span>${msg}</span></p><div class='btnArea'></div></div></div>`);
    const btn = $("<button type='button'>OK</button>").on('click', closePop);
    pop.find('.btnArea').append(btn);
    pop.appendTo('body').find('.pop').animate({ top: '50%', opacity: '1' }, 300);
    pop.find('button').focus();
}
// 예, 아니오 버튼이 있는 팝업 : 콜백함수 존재하면 예 버튼 누른 후 실행
// 아이콘 종류 : "icon-check", "icon-error", "icon-ask", "icon-info", "icon-caution"
export function confirmPop(icon, msg, trueStr, falseStr, callback) {
    function closePop() {
        const bool = $(this).data('bool');
        $(this).parents('.pop').animate({ top: '40%', opacity: '0' }, 300, function () {
            $(this).parents('.confirmPop').fadeOut(200, function () { $(this).remove(); });
        });
        if (bool) {callback();}
    }

    const pop = $(`<div class='confirmPop'><div class='pop'><span class='icon ${icon}'></span><p class='msg'><span>${msg}</span></p><div class='btnArea'></div></div></div>`);
    const yesBtn = $(`<button type='button' class='okBtn'>${trueStr}</button>`).data('bool', true).on('click', closePop);
    const noBtn = $(`<button type='button' class='cancelBtn'>${falseStr}</button>`).data('bool', false).on('click', closePop);
    pop.find('.btnArea').append(yesBtn, noBtn);
    pop.appendTo('body').find('.pop').animate({ top: '50%', opacity: '1' }, 300);
    pop.find('.cancelBtn').focus();
}


// 모바일모드 여부 반환
export function isMobile() {
    if ($('.menuBtn').is(':visible')) return true;
    return false;
}


// 메인메뉴 열기 닫기
export function openMenu() {
    $('.menuBtn, .menu').addClass('open');
    if (isMobile()) $('body').addClass('freeze');
}
export function closeMenu() {
    $('.menuBtn, .menu').removeClass('open');
    if (isMobile()) $('body').removeClass('freeze');
}
export function toggleMenu(btn) {
    if ($(btn).hasClass('open')) {
        closeMenu();
    } else {
        openMenu();
    }
}


// 마지막 포커스 지정 (팝업 종료 시 되돌아가기 위함)
export let _lastFocus;
export function saveFocus(ele) {
    _lastFocus = ele;
}
export function lastFocus() {
    $(_lastFocus).focus();
}


// 숫자 또는 문자열숫자에 소수점 자리수 지정, 천단위 콤마표시
// "123,456,789.123456789".digit(6); -> return 123456789.123456 (Number)
// 123456789.123456789.digit(6); -> return 123456789.123456 (Number)
// "123,456,789.123456789".digitStr(6); -> return 123,456,789.123456 (String)
// 123456789.123456789.digitStr(6); -> return 123,456,789.123456 (String)
export function formatNumber(str) {
    str = str.toString().replace(/(\d),/g, '$1');
    const reg = /(\d+)(\d{3})/;
    if (str.indexOf('.') != -1) {
        const rest = str.replace(/.{0,}\.(\d{0,})/, '$1');
        str = str.replace(/(.{0,})\.\d{0,}/, '$1');
        while (reg.test(str)) str = str.replace(reg, '$1' + ',' + '$2');
        return `${str}.${rest}`;
    }
    while (reg.test(str)) str = str.replace(reg, '$1' + ',' + '$2');
    return str;
}
String.prototype.digit = function (digit) {
    const str = this.replace(/\,/g, '');
    return Math.floor(parseFloat(str) * Math.pow(10, digit)) / Math.pow(10, digit);
};
Number.prototype.digit = function (digit) {
    return Math.floor(this * Math.pow(10, digit)) / Math.pow(10, digit);
};
String.prototype.digitStr = function (digit) {
    let str = String(this).replace(/\,/g, '');
    str = Math.floor(parseFloat(str) * Math.pow(10, digit)) / Math.pow(10, digit);
    return formatNumber(str);
};
Number.prototype.digitStr = function (digit) {
    const num = Math.floor(this * Math.pow(10, digit)) / Math.pow(10, digit);
    return formatNumber(num);
};


// 입력된 문자열에서 숫자만 추출하여 반환
export function getNumber(str) {
    // 마이너스인 경우
    str = str[0] == '-' ? `-${str.substr(1).replace(/[^0-9\.]/g, '')}` : str.replace(/[^0-9\.]/g, '');
    // 소수점 처리
    const dot = str.indexOf('.');
    if (dot != -1) str = `${str.substring(0, dot)}.${str.substr(dot + 1).replace(/\./g, '')}`;
    return str;
}
// 숫자 문자열 자리수마다 콤마 붙여 반환
export function digitNumber(str) {
    // 숫자만 추출
    str = getNumber(str);
    const reg = /(\d+)(\d{3})/;
    // 마이너스 여부
    let neg = '';
    if (str[0] == '-') {
        neg = '-';
        if (str.length == 1) return str;
        str = str.substr(1);
    }
    // 맨 앞이 0일 경우 처리
    str = str.length > 1 && str[0] == '0' && str[1] != '.' ? str.substr(1) : str;
    // 맨 앞이 .일 경우 처리
    str = str[0] == '.' ? `0${str}` : str;
    // 콤마 제거
    str = str.toString().replace(/(\d),/g, '$1');
    // 소수점이 있을 경우
    if (str.indexOf('.') != -1) {
        const rest = str.replace(/.{0,}\.(\d{0,})/, '$1');
        str = str.replace(/(.{0,})\.\d{0,}/, '$1');
        while (reg.test(str)) str = str.replace(reg, '$1' + ',' + '$2');
        return `${neg + str}.${rest}`;
    }
    while (reg.test(str)) str = str.replace(reg, '$1' + ',' + '$2');
    return neg + str;
}

// 숫자 뒤에 K, M 단위 계산
export function setKM(num) {
    // num = parseFloat(num);
    if (num >= 1000000) {
        return `${(num / 1000000.0).digit(1)}M`;
    } if (num >= 1000) {
        return `${(num / 1000.0).digit(1)}K`;
    }
    return num;
}

// 배열의 모든 값 합산
Array.prototype.arraySum = function (start, end) {
    if (start === undefined) start = 0, end = this.length - 1;
    if (end === undefined) end = start, start = 0;
    if (end < start) return 0;
    return this[end] + this.arraySum(start, --end);
};

// 모든 요소 로딩 후 이벤트 처리
export function setAllEvent() {
    // 컨텐츠 애니메이션 실행
    $("[class*='effect-']").setEffect(0);
    $(window).trigger('scroll');
}


// 페이지 로딩중 끄기
export function hideLoading() {
    /*
    $('.loadingPop').fadeOut(200, function () {
        $(this).remove();
    });
    */
}
// 페이지 로딩중 켜기
export function showLoading() {
    /*
    const pop = $('<div class="loadingPop"><div class="loading"></div></div>');
    pop.appendTo('body').fadeIn(200);
    */
}


// ES6 미지원 브라우저 처리
export function checkOldBrowser() {
    const name = window.navigator.userAgent.toLowerCase();
    if (name.indexOf('trident') != -1) {
        location.href = '../html/oldbrowser.html';
    }
}

export function showProgressBar() {
    $('body').append('<div class="progressPop"><p><span class="bar"></span></p></div>');
}
export function setProgressBar(percent) {
    // console.log('call setProgressBar', percent);
    $('.progressPop').find('.bar').css('width', `${percent}%`);
}
export function hideProgressBar() {
    $('.progressPop').remove();
}

export function getExtensionOfFilename(filename) {
    const _lastDot = filename.lastIndexOf('.');
    // 확장자 명만 추출한 후 소문자로 변경
    const _fileExt = filename.substr(_lastDot + 1).toLowerCase();
    return _fileExt;
}


// 첨부파일 폼에 입력
export function selectFile(ele) {
    const hiddenForm = $(ele).siblings('.hiddenForm');
    hiddenForm.trigger('click');
}
// 첨부파일 선택
export function fileInput(ele) {
    const fileNameBox = $(ele).siblings('.fileNameBox');
    const fileName = ele.files[0].name;
    fileNameBox.val(fileName);
}

// 미니차트 기본옵션
export const _basicOption = {
    type: 'line',
    data: {
        labels: [],
        datasets: [{
            borderColor: "#0375df",
            backgroundColor: 'transparent',
            borderWidth: 2,
            data: [],
            fill: false,
            pointBorderWidth:0,
            pointBorderColor: "transparent",
            lineTension: 0,
        }],
    },
    options: {
        animation: {
            duration: 3000
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
            enabled: false
        },
        legend: {
            display: false
        },
        scales: {
            xAxes: [{
                gridLines: {
                    drawTicks: false,
                },
                ticks: {
                    display: false
                }
            }],
            yAxes: [{
                gridLines: {
                    drawTicks: false,
                    zeroLineColor: 'rgba(0,0,0,0.3)'
                },
                ticks: {
                    display: false
                }
            }]
        }
    }
};


// 특정 퍼센트까지 게이지 증가
export function setGauge(ele, percent) {
    var bar = $(ele).find('.bar');
    if($(ele).hasClass('number')) {
        bar.stop().animate({width: percent+'%'}, {
            duration:3000,
            progress: function(a, p) {
                $(this).text((p*percent).toFixed(1)+'%');
            }
        });
    } else {
        bar.stop().animate({width: percent+'%'}, 3000);
    }
}



export const hashCode = (s) => {
  var h = 0, l = s.length, i = 0;
  if ( l > 0 )
    while (i < l)
      h = (h << 5) - h + s.charCodeAt(i++) | 0;
  return h;
}


export const coinToCurrency = (coinSymbol, currencyCode, quantity) => {
    const currencyData = JSON.parse(sessionStorage.getItem("otex_currencyData"));
    const coin = currencyData[`${coinSymbol}_${currencyCode}`];
    return coin['bigCoinPrice'].split(',').join('') * quantity;
}


export const getExplorerUrl = (coinSymbol, address) =>  {
    let url;

    switch(coinSymbol) {
        case 'KISC':
        case 'BBT':
        case 'MCT':
            url = `https://explorer.aiser.io/account/${address}:${coinSymbol}`
            break;
        case 'ETH':
            url = `https://etherscan.io/address/${address}`
            break;
        case 'KLAY':
            url = `https://scope.klaytn.com/account/${address}`;
            break;
        case 'KUSDT':
            url = `https://scope.klaytn.com/account/${address}?tabId=tokenTransfer`;
            break;
        case 'BNB':
            url = `https://bscscan.com/address/${address}`;
            break;
        default:
            url = `https://bscscan.com/address/${address}#tokentxns`;
    }

    return url;
}





// DOM 로딩 후 실행
$(() => {
    // ES6 미지원 브라우저 처리
    checkOldBrowser();

    // 각종 이벤트 처리
    setAllEvent();
    $(window).on('resize', setAllEvent);

    // 숫자 입력 폼 처리 - 천단위 콤마
    $('.digitNumber').on('input', function (e) {
        const str = $(this).val();
        $(this).val(digitNumber(str));
    });


// 10분 자동 로그아웃
//     if(localStorage.getItem('otex_email')) {
//         setTimeout(() => {
//             localStorage.removeItem('otex_email')
//             location.href="/logout";
//         }, 60000 * 10, );
//     }
});
