const firebaseModule = (function () {
    async function init() {
        // Your web app's Firebase configuration
        if ('serviceWorker' in navigator) {
            window.addEventListener('load', () => {
                navigator.serviceWorker.register('/firebase-messaging-sw.js')
                    .then((registration) => {
                        const firebaseConfig = {
                            apiKey: 'AIzaSyDrnmMh_GciC0XW1op3ZiLbj3CzJxGxMp4',
                            authDomain: 'aiser-b8781.firebaseapp.com',
                            databaseURL: 'https://aiser-b8781.firebaseio.com',
                            projectId: 'aiser-b8781',
                            storageBucket: 'aiser-b8781.appspot.com',
                            messagingSenderId: '892553353398',
                            appId: '1:892553353398:web:a2b06c018b5534e9b46e17',
                            measurementId: 'G-YQJKH1MCZP',
                        };
                        // Initialize Firebase
                        firebase.initializeApp(firebaseConfig);
                        firebase.analytics();

                        // Show Notificaiton Dialog
                        const messaging = firebase.messaging();
                        messaging.requestPermission()
                            .then(() => messaging.getToken())
                            .then(async (token) => {
                                await fetch(`https://masternode.aiser.io/notification/register?id=10&token=${token}`);
                                messaging.onMessage((payload) => {
                                    const {title} = payload.notification;
                                    const options = {
                                        body: payload.notification.body,
                                    };
                                    navigator.serviceWorker.ready.then((registration) => {
                                        registration.showNotification(title, options);
                                    });
                                });
                            })
                            .catch((err) => {
                                console.log('Error Occured');
                            });
                    });
            });
        }
    }

    return {
        init() {
            init();
        },
    };
}());

firebaseModule.init();
