import {getCookie, setCookie} from "./common"

export function getEmail() {
    return sessionStorage.getItem('otex_email');
}

export const getPhoneNumber = () => {
    return sessionStorage.getItem('otex_mobile');
}

export function getToken() {
    return sessionStorage.getItem('otex_access_token');
}

export function getAccessToken() {
    if (!isEmpty(sessionStorage.getItem('otex_access_token'))) {
        const tmp = sessionStorage.getItem('otex_access_token');
        const decoded = parseJwt(tmp);
        const { exp } = decoded;

        // 유효기간 확인, 현재일시와 비교
        const current_time = Date.now() / 1000;

        if(exp <= current_time){
            location.href="/login";
        }else{
            return tmp;
        }
    }else{
        location.href="/login";
    }
}

export const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export function isEmpty(value) {
    return value === '' || value == null || (typeof value === 'object' && !Object.keys(value).length);
}

export function setLocalStorageFromApp(otex_access_token, otex_email) {
    localStorage.removeItem('otex_email');
    localStorage.removeItem('otex_access_token');
    localStorage.setItem('otex_email', otex_email);
    localStorage.setItem('otex_access_token', otex_access_token);

    sessionStorage.removeItem('otex_email');
    sessionStorage.removeItem('otex_access_token');
    sessionStorage.setItem('otex_email', otex_email);
    sessionStorage.setItem('otex_access_token', otex_access_token);
}

export function setTempSessionStorage(params) {
    sessionStorage.removeItem('otex_email');
    sessionStorage.removeItem('otex_access_token');
    sessionStorage.removeItem('otex_mobile');

    const temp = JSON.parse(params);
    sessionStorage.setItem('otex_email', temp.email);
    sessionStorage.setItem('otex_access_token', temp.access_token);
    sessionStorage.setItem('otex_mobile', temp.mobile);
}

export function initLogin() {
    sessionStorage.removeItem('otex_email');
    sessionStorage.removeItem('otex_access_token');
}

export function logout() {
    confirmPop('icon-ask', __('로그아웃 하시겠습니까?'), __('로그아웃'), __('취소'), () => {
        removeLoginInfo();

        location.href="/logout";
    });
}


export const removeLoginInfo = () => {
    sessionStorage.removeItem('otex_email');
    sessionStorage.removeItem('otex_access_token');
    sessionStorage.removeItem('otex_mobile');
}

export function isLoginNeed() {
    const cur_path = $(location).attr('pathname');

    let boolLoginNeed = false;
    const loginNotNeedUrl = ['/login', '/logout', '/main', '/notice', '/faq', '/pin', '/'];
    boolLoginNeed = loginNotNeedUrl.includes(cur_path);

    if (!boolLoginNeed) {
        const token = sessionStorage.getItem('otex_access_token');

        if(isEmpty(token)){
            alertPop("icon-error", "로그인이 필요합니다.", function() {
                location.href="/login";
            });
        }
    }
}

export function setCurrentCoin(coinSymbol) {
    if(isEmpty(coinSymbol)){
        coinSymbol = 'BTC';
    }
    sessionStorage.setItem('select_coin', coinSymbol);
}

export function getCurrentCoin() {
    let result = sessionStorage.getItem('select_coin');
    return result ? result : 'BTC';
}

export function transCurrentCoin(coinType) {
    return coinType;
}

export function transConvertCurrentCoin(coinType) {
    return coinType;
}

export const setCurrentCurrency = (currency) => sessionStorage.setItem('select_currency', currency);

export function getCurrencyByPhoneNumber(phoneNumber) {
    if(new RegExp(/^\+82/).test(phoneNumber)) {
        return 'KRW';
    } else if(new RegExp(/^\+86/).test(phoneNumber)) {
        return 'CNY';
    }

    return 'USD';
}

export function getCurrentCurrency() {
    let result = sessionStorage.getItem('select_currency');

    if (!result) {
        result = getCurrencyByPhoneNumber(sessionStorage.getItem('otex_mobile'));
    }

    return result ? result : 'USD';
}

export function getCurrencyData() {
    return JSON.parse(sessionStorage.getItem('otex_currencyData'));
}

export function setCurrencyData(data) {
    return sessionStorage.setItem('otex_currencyData', JSON.stringify(data));
}

export function getHostName() {
    var curHostName = 'https://otexm.com/';

    if(location.hostname === 'localhost'){
        curHostName = 'localhost:3000/';
    }
    return curHostName;
}

export const getLanguage = () => localStorage.getItem('select_language');
export const setLanguage = (language) => localStorage.setItem('select_language', language);

export const getDeviceTokenType = () => {
    const ua = navigator.userAgent.toLowerCase()

    if(/android/i.test(ua)) {
        return 'ANDROID'
    } else if(/iPhone|iPad|iPod/i.test(ua)) {
        return 'IOS'
    } else {
        return 'WEB'
    }
}

// 팝업 닫기
export const closePopup = (btn, cookieName) => {
    const isChecked = $(btn).parents('.pop').find('.hidePop24h').prop('checked');
    if(isChecked) {
        setCookie(cookieName, 'hide', 1);
    }
    $(btn).parents('.intro-pop').hide();
}

export const showPopup = (name) => {
    if (getCookie(`${name}`) !== 'hide') {
        $(`.${name}`).css('display', 'flex');
    }
}

