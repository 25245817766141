import i18n, {
    __,
} from 'i18n-for-browser';
import ko from '../locales/ko.json';
import en from '../locales/en.json';
import zh from '../locales/zh.json';
import ja from '../locales/ja.json';

i18n.configure({
    locales: {
        ko, en, zh, ja,
    },
    defaultLocale: 'en',
    cookieName: 'lang',
});

export {
    i18n,
    __,
};
