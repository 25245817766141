import gql from 'graphql-tag';

export const ALL_CUSTODY_ACCOUNT_HISTORIES = gql`
    query allCustodyAccountHistories($email: String,
        $page: Int, $perPage: Int, $sortField: String, $sortOrder: String) {
        allCustodyAccountHistories(filter: {email: $email},
            page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder) {
            id
            custodyAccount {
                custodyNode {
                    custody {
                        type
                    }
                    user {
                        email
                    }
                }
            }
            type
            amount
            createdAt
        }
    }
`;



export const GET_TRADE_REGISTRATIONS_OF_BUY_SELLER = gql`
    query getTradeRegistrationsOfBuySeller($coinType:CoinType!, $currencyCode:CurrencyCode!) {
        getTradeRegistrationsOfBuySeller(coinType: $coinType, currencyCode: $currencyCode) {
            id
            registrant {
                email
            }
            price
            quantity
            type
            minimumTradableQuantity
        }
    }
`;
