import {__} from "i18n-for-browser"
import {alertPop, lastFocus, saveFocus, showLoading} from "./lib/common"
import {
    allCurrencies,
    getBaseCurrencies, getBaseTokens,
    getCoinTypeView,
    getCurrency,
    getNoticeList,
    getNoticePost, getNotReceivedPosts,
    getTotalExchangeInfoByCurrency, modifyAllReceivedPosts, removeLoginInfo,
    setCurrentCurrency
} from "./export-common"
import BigNumber from "bignumber.js"

// 나의 자산 팝업 보기
export const openMoneyChart = (btn) => {
    // 팝업 표시
    saveFocus(btn);
    $('body').addClass('freeze');
    const pop = $('.myMoneyPop').flexFadeIn();
    pop.find('.closeBtn').focus();
}

// 나의 자산 팝업 닫기
export const closeMoneyPop = (btn) => {
    $(btn).parents('.popLayer').flexFadeOut();
    $('body').removeClass('freeze');
    lastFocus();
}

// 코인선택 팝업 닫기
export const closeCoinPop = (btn) => {
    // 코인이름 변경
    $('.selectCoinBtn').text($('.checkBtn.current').text());
    // 닫기
    $(btn).parents('.popLayer').flexFadeOut();
    $('body').removeClass('freeze');
    lastFocus();
}

// 코인선택 팝업 열기
export const openCoinPop = (btn) => {
    saveFocus(btn);
    $('body').addClass('freeze');
    const pop = $('.coinPop').flexFadeIn();
    pop.find('.closeBtn').focus();
}

// 코인선택
export const selectCoin = (btn) => {
    $(btn).addClass('current').parents('li').siblings()
        .find('.checkBtn')
        .removeClass('current');
    closeCoinPop(btn);
}

// 시세 테이블 클릭 시 해당 코인 거래 페이지 이동
export const gotoTrade = (id) => {
    setCurrentCoin(id);
    location.href = '/trade/trade';
}

// 나의 자산 차트 표시
let moneyChart1;
let
    moneyChart2;
let moneyCanvas1;
let
    moneyCanvas2;
const isEmptyChart = true;
const moneyOption = {
    type: 'doughnut',
    data: {
        datasets: [{
            data: [], // 팝업 테이블로부터 가져옴
            borderWidth: 0,
            backgroundColor: ['#4FC3F7', '#1DE9B6', '#FFB300', '#D7CCC8',
                '#9b42d4', '#1db680', '#740ff0', '#e53e3e',
                '#4FC307', '#1D99B6', '#AAB300', '#e87f7f'],
        }],
        labels: [],
    },
    options: {
        legend: {
            position: 'right',
            labels: {
                fontColor: '#111111',
                fontSize: 11,
            },
        },
    },
};

export const drawAssetChart = (coinName, total, priceUnit) => {
    // 코인별 자산 입력
    moneyOption.data.datasets[0].data.push(total);
    moneyOption.data.labels.push(getCoinTypeView(coinName));

    // 코인별 자산 총합
    const myTotalMoney = moneyOption.data.datasets[0].data.arraySum();

    // 총자산 입력
    $('#myTotalMoney').html(`${myTotalMoney.digitStr(0)}&nbsp;${priceUnit}`);

    // 차트 업데이트
    if (!isEmpty(moneyCanvas1)) {
        moneyChart1.update();
        moneyChart2.update();
    }

    const temp = `otex_myBalance_total_${priceUnit}`;
    sessionStorage.setItem(temp, (myTotalMoney.digitStr(0)));
    $(".otex_myBalance_total").text((myTotalMoney.digitStr(0)) + ' ' + priceUnit);
}

// 나의 자산 표시
export const setUserAsset = async (coinName, coinPrice, priceUnit) => {
    // 지갑 정보 가져오기
    const token = getToken();
    const email = getEmail();

    // 코인가격 숫자로 변환. 소수점 6까지
    coinPrice = coinPrice.digit(6);

    if (!isEmpty(token) && !isEmpty(email)) {
        const wallets = (await getUserWallets(token, email)).data.getWallets;

        // 지갑이 없을 경우 고려
        if (wallets.length > 0) {
            $('.noWalletUser').hide();
            $('.totalMoney .left, .totalMoney .right').css('display', 'inline-flex');
            const table = $('.dataTable tbody');
            const wallet = wallets.filter((obj) => obj.coinType.indexOf(coinName) === 0)[0];

            const coinNameView = getCoinTypeView(coinName);

            if (wallet !== undefined) {
                const tr = `${'<tr>'
                    + '<td><p class="coinName">'}${coinNameView}</p></td>`
                    + `<td><p class="coinPrice">${coinPrice.digitStr(8)}&nbsp;${priceUnit}</p></td>`
                    + `<td><p class="coinAmount">${wallet.balance.digitStr(8)}</p></td>`
                    + `<td><p class="total">${(coinPrice * wallet.balance).digitStr(8)}&nbsp;${priceUnit}</p></td>`
                    + '</tr>';
                table.append(tr);

                const temp = `otex_myBalance_${wallet.coinType}`;
                sessionStorage.setItem(temp, (coinPrice * wallet.balance));

                // 차트 표시
                drawAssetChart(coinName, (coinPrice * wallet.balance), priceUnit);
            }
        } else {
            $('.noWalletUser').show();
            $('.totalMoney .left, .totalMoney .right').hide();
        }

    }
}

const checkNotReceivedPosts = async () => {
    const {data} = await getNotReceivedPosts()
    if(0 < data.getNotReceivedPosts.length) {
        confirmPop('icon-ask', __(`OTEX 매니저가 남긴 새로운 내용이 있습니다.<br/> 문의 내역에서 내용을 확인 하기 바랍니다.`),
            __('문의내역으로 이동'), __('취소'), async () => {
            await modifyAllReceivedPosts()
            document.location.href = '/service/cs_mylist'
        });
    }
}

let coinUrls = {};
document.addEventListener('DOMContentLoaded', async () => {
    isLoginNeed();

    checkNotReceivedPosts()


    if(getAccessToken()) {
        $('.is-login').show();
        $('.needLogin').hide();
    } else {
        $('.is-login').hide();
        $('.needLogin').show();
    }
    $('.totalMoney').show();

    // 차트 생성
    moneyCanvas1 = $('#myMoneyCanvas1');
    moneyCanvas2 = $('#myMoneyCanvas2');

    if (!isEmpty(moneyCanvas1)) {
        moneyChart1 = new Chart(moneyCanvas1, moneyOption);
        moneyChart2 = new Chart(moneyCanvas2, moneyOption);
    }

    // 공지사항 가져와 출력
    getNoticeList('otex.io', 'notice', getCookie("lang"), '').then((res) => {
        createNotice(res.data.allPosts);
    });

    BigNumber.set({DECIMAL_PLACES: 10, ROUNDING_MODE: 3});

    showLoading();

    // 코인별 사이트
    (await allCurrencies()).data.allCurrencies.map((c) => {
        return coinUrls[c['symbol']] = c['siteUrl'];
    });

    // 현재 통화에 맞게 표시
    const currentCurrency = getCurrentCurrency();
    currency($(`.currency-tab[data-currency=${currentCurrency}]`).get(0));

    $('.currency-tab').on('click', (e) => {
        currency(e.target);
    });
});

// 공지사항 팝업 열기
export const noticePop = (obj, index) => {
    // 쿠키가 존재하면 패스
    if (getCookie(`pop_${obj.id}`)) return;

    // 팝업 html
    let pos = (index + 1) * 10;
    let zindex = index + 2000;
    const $pop = $(`<div class="noticePop" id="pop_${obj.id}" style="z-index:${zindex};left:${pos}px;top:${pos}px;">
        <button type="button" class="closeBtn" onclick="closeNoticePop(this)"></button>
        <p class="title">${obj.title}</p>
        <div class="contents">111</div>
        <label><input type="checkbox" class="hideToday" />${__('하루 동안 보지 않기')}</label>
    </div>`);
    // 본문 불러와 표시
    getNoticePost(obj.id).then((res) => {
        $pop.find('.contents').html(res.data.getPost.contents);
        $pop.appendTo('body').fadeIn(200);
    });
}

// 공지사항 팝업 닫기
export const closeNoticePop = (btn) => {
    const $pop = $(btn).parents('.noticePop');
    const isChecked = $pop.find('.hideToday').prop('checked');
    const popId = $pop.attr('id');
    if (isChecked) {
        setCookie(popId, true, 1);
    }
    $pop.fadeOut(200);
}

// 공지사항 목록 출력
export const createNotice = (listArr) => {
    const noticeWrap = $('.noticeWrap');
    if (listArr.length > 0) {
        noticeWrap.find('.row').remove();
        // 차례로 출력
        $.each(listArr, function(index) {
            // 5개만 출력
            if(index < 5) {
                const date = this.createdAt.substr(0, 10);
                const row = `${'<div class="row">'
                    + '<a href="/service/notice_view?id='}${this.id}" class="link ellipsis">${this.title}</a>`
                    + `<span class="date">${date}</span>`
                    + '</div>';
                noticeWrap.append(row);
                // 팝업으로 지정된 경우
                if (this.isPopup) {
                    // 일단 숨김
                    //noticePop(this, index);
                }
            }
        });
    }
}

// 암호화화폐 시세
// 통화단위 탭 선택
export const currency = (btn) => {
    // 차트 데이터 초기화
    moneyOption.data.datasets[0].data = [];
    moneyOption.data.labels = [];
    $('.dataTable tbody').empty();

    $(btn).addClass('current').siblings().removeClass('current');
    const selectMoney = $(btn).text();

    setCurrentCurrency(selectMoney);

    let currencyTemp = null;
    getCurrency().then((res) => {
        if (res.data.result === 'success') {
            getTotalExchangeInfoByCurrency(getBaseTokens())
                .then((res2) => {
                    createCurrencyData(res.data.data);
                    parseCurrencyData(res.data.data, selectMoney, res2.data['getTotalExchangeInfoByCurrency']);
                });

            currencyTemp = res.data.data.currency;
        } else {
            alertPop('icon-error', 'Currency lookup failed, Try refresh.', () => {
            });
        }
    }).catch((error) => {
        alertPop('icon-error', 'Currency lookup failed, Try refresh..', () => {
        });
    });
}

export const parseCurrencyData = (data, money, exchangeInfos) => {
    const KRWpUSD = data.currency.KRW;
    const CNYpUSD = data.currency.CNY;

    let currentCurrency = 1.0;

    if (money === 'KRW') {
        currentCurrency = KRWpUSD;
    } else if (money === 'CNY') {
        currentCurrency = CNYpUSD;
    }

    let coinsData = data.coins;
    if (coinsData) {
        if (typeof data.coins === 'string' || data.coins instanceof String) {
            coinsData = JSON.parse(data.coins);
        }

        const coinInfoList = document.getElementsByClassName('coinInfo')[0];
        coinInfoList.innerHTML = '';

        for(let i = 0; i < getBaseTokens().length; i++) {
            const symbol = getBaseTokens()[i];
            const coin = coinsData.filter((e) => {
                return e.symbol === symbol;
            })[0];

            if (!coin || coin.symbol === 'KISC') {
                continue;
            }

            const ei = exchangeInfos.filter((e) => {
                return e.coinType === symbol;
            });

            if (0 < ei.length) {
                addCoinInfo(coin, currentCurrency, coinInfoList, money, ei[0]['totalQuantity'], ei[0]['price']);
            }
        }
    }
}


export const createCurrencyData = (currencyData) => {
    let data = {};
    getBaseCurrencies().forEach(currency => {
        getBaseTokens().forEach(token => {
            const coin = currencyData['coins'].find(e => {
                return e.symbol === token;
            });

            if (!coin) {
                return;
            }

            let bigPercent24h = BigNumber(coin.percent_change_24h);
            bigPercent24h = bigPercent24h.decimalPlaces(2);
            let bigCoinPrice = BigNumber(coin.price * currencyData.currency[currency]);

            if (1 <= parseInt(bigCoinPrice)) {
                bigCoinPrice = bigCoinPrice.decimalPlaces(2);
            } else {
                bigCoinPrice = bigCoinPrice.decimalPlaces(8);
            }

            let bigVolume24h = BigNumber(coin.volume_24h).dividedBy(1000000);
            bigVolume24h = bigVolume24h.decimalPlaces(2);

            let bigVarience = bigCoinPrice.times(bigPercent24h).times(0.01);
            bigVarience = bigVarience.decimalPlaces(2);

            data[`${coin.symbol}_${currency}`] = {
                coinSymbol: coin.symbol,
                currencyType: currency,
                bigPercent24h: bigPercent24h.toFormat(),
                bigCoinPrice: bigCoinPrice.toFormat(),
                bigVolume24h: bigVolume24h.toFormat(),
                bigVarience: bigVarience.toFormat(),
            };
        });
    });

    sessionStorage.setItem('otex_currencyData', JSON.stringify(data));
}

// 웹사이트로 이동
export const gotoWebSite = (url) => {
    if(url != '') {
        window.open(url, '_blank');
    }
}

export const addCoinInfo = (coin, currency, coinInfoList, money, totalQuantity, price) => {
    const currencyData = JSON.parse(sessionStorage.getItem('otex_currencyData'))[`${coin.symbol}_${money}`];

    let pontColor = 'red';
    if (currencyData.bigPercent24h < 0) {
        pontColor = 'blue';
    }

    let htmlPrice = price;

    if (htmlPrice === 0) {
        htmlPrice = Math.round(currencyData.bigCoinPrice.replace(/[^0-9.]/g, '') * 100) / 100;
    }

    const coinTypeView = getCoinTypeView(coin.symbol);

    coinInfoList.insertAdjacentHTML('beforeend',
        `<tr tabindex="0" title="Website" onclick="gotoWebSite('${coinUrls[coin.symbol]}')">
                <td>
                    <div class="flex">
                        <div><img src="../img/coin_${coin.symbol}.png"></div>
                        <div><p>${coin.name}</p><p class="gray">${coinTypeView}/${money}</p></div>
                    </div>
                </td>
                <td><p class="${pontColor}">${htmlPrice}</p></td>
                <td><p>${numeral(totalQuantity).format('0,0.00')}</p></td>
                <td><p>${
            numeral(currencyData.bigCoinPrice.split(',').join('') * totalQuantity).format('0,0.00')
        }</p></td>
            </tr>
            <tr>
                <td colspan="4">
                    <div class="btnArea">
                        <button style="${coin.symbol === 'SRC' ? 'display: none' : ''}" onClick="goBuySeller('${coin.symbol}', 'BUY')" type="button" class="btn fill pink">${__('즉시구매')}</button>
                        <button style="${coin.symbol === 'SRC' ? 'display: none' : ''}" onClick="goBuySeller('${coin.symbol}', 'SELL')" type="button" class="btn fill sky">${__('즉시판매')}</button>
                        <button onclick="gotoTrade('${coin.symbol}')" type="button" class="btn fill">${__('일반거래')}</button>
                    </div>
                </td>
            </tr>`);

    // 나의 자산 변수에 데이터 입력
    setUserAsset(coin.symbol, currencyData.bigCoinPrice, money);
}

export const goBuySeller = (coinType, mode) => {
    setCurrentCoin(coinType);
    document.location = `/trade/buyseller?mode=${mode}`;
}

// 현재 시세를 다른 모듈에서도 쓰기 위함. 불필요한 API 호출을 줄이기 위함
var setCurrencyData = {};
