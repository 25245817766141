import client, {query} from './apolloClient';
import gql from 'graphql-tag';
import {
    errorMessage,
    getBaseTokens,
    getExplorerUrl,
    notifyToAdmin,
    parseJwt,
    signUp,
} from '../export-common';
import axios from "axios";

export default class Otex {
    constructor(apiKey, tokenName = 'token') {
        this.apiKey = apiKey;
        this.tokenName = tokenName;
    }

    signOut = () => {
        localStorage.removeItem(this.tokenName);
    }

    getEmail = () => {
        return parseJwt(this.getToken()).email;
    }

    getToken = () => {
        return localStorage.getItem(this.tokenName);
    }


    getBaseTokens = () => {
        return getBaseTokens();
    }


    registerTrade = (accessToken, type, coinType, email, pin, price, quantity, minimumTradableQuantity, currencyCode) => {
        return client.mutate({
            mutation: gql`
                mutation registerTrade($accessToken:String!,
                    $type:TradeRegistrationType!,
                    $coinType:CoinType!,
                    $email:String!,
                    $pin:String,
                    $price:BigDecimal!,
                    $quantity:BigDecimal!,
                    $minimumTradableQuantity:BigDecimal,
                    $currencyCode: CurrencyCode
                ) {
                    registerTrade(input: {
                        accessToken:$accessToken,
                        type:$type,
                        coinType:$coinType,
                        email:$email,
                        pin:$pin,
                        price:$price,
                        quantity:$quantity,
                        minimumTradableQuantity:$minimumTradableQuantity,
                        currencyCode: $currencyCode
                    }) {
                        id
                        registrant {
                            email
                        }
                        type
                        coinType
                        price
                        quantity
                        minimumTradableQuantity
                        createdAt
                    }
                }
            `,
            variables: {
                accessToken,
                type,
                coinType,
                email,
                pin,
                price,
                quantity,
                minimumTradableQuantity,
                currencyCode
            },
        });
    }

    getErrorMessage = (type) => {
        return errorMessage[type];
    }


    updateWithdrawalSettings = (coinType, address, auto) => {
        return client.mutate({
            mutation: gql`
                mutation updateWithdrawalSettings($coinType:CoinType!, $address:String!, $auto:Boolean!) {
                    updateWithdrawalSettings(coinType: $coinType, address: $address, auto: $auto) {
                        id
                        coinType
                        address
                        auto
                    }
                }
            `,
            variables: {
                coinType,
                address,
                auto,
            },
        });
    }


    getTrades = (coinType, status) => {
        return client.query({
            query: gql`
                query getTrades($coinType: CoinType, $status: [TradeApplicationStatus]) {
                    getTrades(coinType: $coinType, status: $status) {
                        id
                        seller {
                            email
                        }
                        buyer {
                            email
                        }
                    }
                }
            `,
            variables: {
                coinType,
                status,
            },
        });
    }


    getWallets = (accessToken, email, types) => {
        if (!types) {
            types = 'OTEX';
        }

        return client.query({
            query: gql`
                query getWallets($accessToken:String!, $email:String!, $types:[WalletType]!) {
                    getWallets(accessToken:$accessToken, email:$email, types:$types) {
                        type
                        coinType
                        address
                        balance
                    }
                }
            `,
            variables: {
                accessToken,
                email,
                types,
            },
        });
    }


    notifyToAdmin = (message) => {
        return notifyToAdmin(message);
    }


    getExplorerUrl = (coinType, address) => {
        return getExplorerUrl(coinType, address);
    }


    signUp = (email, username, password, phoneNumber, bankName, accountName, accountNumber) => {
        localStorage.removeItem(this.tokenName);

        return signUp(email, username, password, phoneNumber, bankName, accountName, accountNumber);
    }


    convertCurrency = async (symbol, currency, quantity) => {
        const { data } = await axios.put('https://kaisermisc.otexm.com/currency');
        const coins = data.data.coins;
        const exchangeRates = data.data.currency;

        const coin = coins.find(coin => coin.symbol === symbol);

        if (coin === undefined) {
            throw `Can't found coin`;
        }

        const exchangeRate = exchangeRates[currency];

        if (exchangeRate === undefined) {
            throw `Can't found coin`;
        }

        return coin.price * quantity * exchangeRate;
    }


    convertCurrencyByPrice = async (symbol, currency, price) => {
        const { data } = await axios.put('https://kaisermisc.otexm.com/currency');
        const coins = data.data.coins;
        const exchangeRates = data.data.currency;

        const coin = coins.find(coin => coin.symbol === symbol);

        if (coin === undefined) {
            throw `Can't found coin`;
        }

        const exchangeRate = exchangeRates[currency];

        if (exchangeRate === undefined) {
            throw `Can't found coin`;
        }

        return price / (Math.round((coin.price * exchangeRate) * 100) / 100);
    }


    getFranchisee = async () => {
        const result = await client.query({
            query: gql`
                query getCurrentUser {
                    getCurrentUser {
                        email
                        franchisee {
                            coinType
                            withdrawAddress
                            recommendedUser {
                                email
                            }
                        }
                    }
                }`
        });

        return result.data.getCurrentUser.franchisee;
    }


    getTradeRegistrations = async(registrantEmail, coinType, registrationStatus) => {
        return client.query({
            query: gql`
                query getTradeRegistrations($registrantEmail:String!, $coinTypes:[CoinType], $registrationStatus: TradeRegistrationStatus) {
                    getTradeRegistrations(filter: {registrantEmail:$registrantEmail, coinTypes:$coinTypes, registrationStatus: $registrationStatus}) {
                        id
                        currencyCode
                        type
                        coinType
                        price
                        quantity
                        remainQuantity
                        minimumTradableQuantity
                        createdAt
                        status
                    }
                }
            `,
            variables : {
                registrantEmail,
                coinType,
                registrationStatus,
            },
        });

    }
}

