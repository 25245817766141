import $ from 'jquery';
// import moment from 'moment';
// import Chart from 'chart.js';
import {getChildNodeUsers, getCustodyROIChartData} from './lib/otex-api';
import {getEmail} from './lib/otex-common';

const drawCustodyROIChartData = async (canvas, custodyId, email) => {
    const res = await getCustodyROIChartData(custodyId, email);
    const data = res.data['getCustodyROIChartData'];

    if (data != null) {
        const days = [];
        const values = [];

        data.map(({date, amount}) => {
            days.push('YYYY.MM.DD');
            values.push(amount);
        });

        const option = $.extend({}, _basicOption);
        option.data.labels = days;
        option.data.datasets[0].data = values;
        new Chart(canvas, option);
    }
};


const showChildNodeUsers = (container, custodyId, email) => {
    getChildNodeUsers(custodyId, email).then((response) => {
        const data = response.data.getChildNodeUsers;

        for (let i = 0; i < data.length; i++) {
            const date = data[i].createdAt.substr(0,10);
            const time = data[i].createdAt.substr(11,8);

            $(container).append(`
                <tr>
                    <td><p>${data[i].id}</p></td>
                    <td><p>${data[i].user.email}</p></td>
                    <td><p>${date} ${time}</p></td>
                </tr>
            `);
        }

    }).catch((response) => {
       console.dir(response);
    });
}

$(() => {
    drawCustodyROIChartData($('.miniCanvas'), custodyId, getEmail());
    showChildNodeUsers($('#child-tbody'), custodyId, getEmail());
});
