import gql from 'graphql-tag';
import client from './apolloClient';

export function allTransactions(perPage) {
    return client.query({
        query: gql`
            query allTransactions($perPage: Int) {
                allTransactions(perPage: $perPage) {
                    id
                    blockHash
                    blockTimestamp
                    to
                }
            }
        `,
        variables: {
            perPage,
        },
    });
}

export const findUser = async (email) => {
    return await client.query({
        query: gql`
            query findUser($email: String!){
                findUser(email: $email) {
                    email
                    franchisee {
                        name
                        manager {
                            wallets {
                                type
                                coinType
                                address
                            }
                        }
                    }
                }
            }
        `,
        variables: {
            email,
        },
    });
}

/**
 * 회원생성하기
 * @param 회원정보 객체
 * @returns {query|Promise<PermissionStatus>}
 */
export function otexUserCreate(obj) {
    const {
        email, username, password, phoneNumber, custodyId, referrer, apiKey,
        bankName, accountName, accountNumber,
    } = obj;

    const variables = {
        email: email.trim(),
        username: username.trim(),
        password: password.trim(),
        phoneNumber: phoneNumber.trim(),
        roleName: 'ROLE_OTEX_USER',
        apiKey: apiKey,
        bankName,
        accountName,
        accountNumber,
    };

    if (custodyId) {
        variables.custodyId = custodyId;
    }

    if (referrer) {
        variables.referrer = referrer;
    }

    localStorage.removeItem('token');

    return client.mutate({
        mutation: gql`
            mutation signUp($email:String!, $username:String!, $password:String!, $roleName: RoleName!, $phoneNumber: String,
                $bankName: String, $accountName: String, $accountNumber: String,
                $custodyId: Int, $referrer: String, $recommendedUserEmail: String, $apiKey: String) {
                signUp(email: $email, username: $username, password: $password, roleName: $roleName, phoneNumber: $phoneNumber,
                    bankName: $bankName, accountName: $accountName, accountNumber: $accountNumber,
                    custodyId: $custodyId, referrer: $referrer, recommendedUserEmail: $recommendedUserEmail, apiKey: $apiKey) {
                    id
                    createdAt
                    email
                    phoneNumber
                    roles {
                        name
                    }
                }
            }
        `,
        variables,
    });
}


export const signUp = (email, username, password, phoneNumber, bankName, accountName, accountNumber) => {
    localStorage.removeItem('token');
    return client.mutate({
        mutation: gql`
            mutation signUp($email:String!, $username:String!, $password:String!, $roleName: RoleName!, $phoneNumber: String,
                $bankName: String, $accountName: String, $accountNumber: String,
                $custodyId: Int, $referrer: String, $recommendedUserEmail: String) {
                signUp(email: $email, username: $username, password: $password, roleName: $roleName, phoneNumber: $phoneNumber,
                    bankName: $bankName, accountName: $accountName, accountNumber: $accountNumber,
                    custodyId: $custodyId, referrer: $referrer, recommendedUserEmail: $recommendedUserEmail) {
                    id
                    createdAt
                    email
                    phoneNumber
                    roles {
                        name
                    }
                }
            }
        `,
        variables: {
            email,
            username,
            password,
            phoneNumber,
            roleName: 'ROLE_OTEX_USER',
            bankName,
            accountName,
            accountNumber,
        },
    });
}


/**
 * 공지사항 목록
 * @param keyword
 * @returns {query|Promise<PermissionStatus>}
 */
export function getNoticeList(groupName, boardName, locale, keyword) {
    return client.query({
        query: gql`
            query allPosts($groupName:String, $boardName:String, $locale:String, $keyword:String) {
                allPosts(filter: {groupName:$groupName, boardName:$boardName, locale:$locale, q:$keyword}) {
                    id
                    createdAt
                    title
                    isPopup
                }
            }
        `,
        variables: {
            groupName,
            boardName,
            locale,
            keyword,
        },
    });
}

/**
 * 공지사항 상세
 * @param id
 * @returns {query|Promise<PermissionStatus>}
 */
export function getNoticePost(id) {
    return client.query({
        query: gql`
            query getPost($id: ID!){
                getPost(id:$id) {
                    id
                    title
                    createdAt
                    contents
                }
            }
        `,
        variables: {
            id,
        },
    });
}

/**
 * 폰번호 가져오기
 * @param email
 * @returns {query|Promise<PermissionStatus>}
 */
export function getUserPhone(email) {
    return client.query({
        query: gql`
            query findUser($email: String!){
                findUser(email: $email) {
                    phoneNumber
                }
            }
        `,
        variables: {
            email,
        },
    });
}

/**
 * 1:1문의 작성
 * @param email
 * @param title
 * @param contents
 */
export function createCSPost(from, title, contents, attachments = null) {
    return client.mutate({
        mutation: gql`
            mutation createPost($from: String!, $title:String!, $contents:String!, $boardId: ID!, $attachments: [UploadFile]) {
                createPost(from: $from, title:$title, contents:$contents, boardId:$boardId, attachments:$attachments) {
                    id
                    title
                    contents
                }
            }
        `,
        variables: {
            from,
            title,
            contents,
            boardId: 4,
            attachments,
        },
    });
}

/**
 * 1:1문의 내역 가져오기
 * @param email
 * @returns {query|Promise<PermissionStatus>}
 */
export function getCSList(email) {
    return client.query({
        query: gql`
            query allPosts($groupName:String, $boardName:String, $email:String) {
                allPosts(filter: {groupName:$groupName, boardName:$boardName, email:$email}) {
                    id
                    title
                    contents
                    comments {
                        id
                        contents
                    }
                }
            }
        `,
        variables: {
            groupName: 'otex.io',
            boardName: 'qna',
            email,
        },
    });
}

export const updateBank = (username, bankName, accountNumber, image) => {
    return client.mutate({
        mutation: gql`
            mutation updateBankAccountByUser($username:String, $bankName: String, $accountNumber:String, $image: String) {
                updateBankAccountByUser(username:$username, bankName:$bankName, accountNumber:$accountNumber, image:$image) {
                    id
                    bankName
                    username
                    accountNumber
                    image
                }
            }
        `,
        variables: {
            accountNumber,
            username,
            bankName,
            image,
        },
    });
}

/**
 * 계좌정보 불러오기
 * @param email
 * @returns {query|Promise<PermissionStatus>}
 */
export function getBank(email) {
    return client.query({
        query: gql`
            query getBankAccountByUser($email:String!) {
                getBankAccountByUser(email:$email) {
                    id
                    username
                    accountNumber
                    bankName
                    image
                    certified
                }
            }
        `,
        variables: {
            email,
        },
    });
}

/** *
 * 거래 등록
 *
 * @param accessToken
 * @param tradeMode
 * @param coinType
 * @param email
 * @param pin
 * @param price
 * @param amount
 * @returns {*}
 */
export function registerTrade(accessToken, tradeMode, coinType, email, pin, price, amount, min, currencyCode) {
    return client.mutate({
        mutation: gql`
            mutation registerTrade($accessToken:String!,
                $type:TradeRegistrationType!,
                $coinType:CoinType!,
                $email:String!,
                $pin:String,
                $price:BigDecimal!,
                $quantity:BigDecimal!,
                $minimumTradableQuantity:BigDecimal,
                $currencyCode: CurrencyCode
            ) {
                registerTrade(input: {
                    accessToken:$accessToken,
                    type:$type,
                    coinType:$coinType,
                    email:$email,
                    pin:$pin,
                    minimumTradableQuantity:$minimumTradableQuantity,
                    price:$price,
                    quantity:$quantity,
                    currencyCode: $currencyCode
                }) {
                    id
                    registrant {
                        email
                    }
                    type
                    coinType
                    price
                    quantity
                    minimumTradableQuantity
                    createdAt
                }
            }
        `,
        variables: {
            accessToken,
            type: tradeMode,
            coinType,
            email,
            pin,
            price,
            quantity: amount,
            minimumTradableQuantity: min,
            currencyCode
        },
    });
}

/**
 * 유저 지갑 불러오기
 * @param accessToken
 * @param email
 * @returns {query|Promise<PermissionStatus>}
 */
export function getUserWallets(accessToken, email, types) {
    if (!types) {
        types = 'OTEX';
    }

    return client.query({
        query: gql`
            query getWallets($accessToken:String!, $email:String!, $types:[WalletType]!) {
                getWallets(accessToken:$accessToken, email:$email, types:$types) {
                    type
                    coinType
                    address
                    balance
                }
            }
        `,
        variables: {
            accessToken,
            email,
            types,
        },
    });
}


/**
 * 지갑 생성하기
 * @param coinType
 * @param accessToken
 * @returns {query|Promise<PermissionStatus>}
 */
export function addAddOtexWallet(accessToken, coinType) {
    return client.mutate({
        mutation: gql`
            mutation addOtexWallet($accessToken: String, $coinType:CoinType) {
                addOtexWallet(accessToken:$accessToken, coinType:$coinType) {
                    address
                    availableAmountToWithdraw
                    balance
                    coinType
                    createdAt
                    cumulativeBalance
                    id
                    type
                }
            }
        `,
        variables: {
            accessToken,
            coinType,
        },
    });
}

/**
 * 거래등록 내역 가져오기
 * @param ids
 * @param email
 * @param coinTypes
 * @param passedSince
 * @param passedBefore
 * @returns {query|Promise<PermissionStatus>}
 */
export const getTradeRegistrations = (variables) => {
    return client.query({
        query: gql`
            query getTradeRegistrations($ids:[Int], $registrantEmail:String, $coinTypes:[CoinType], $passedSince:ZonedDateTime, $passedBefore:ZonedDateTime) {
                getTradeRegistrations(filter: {ids: $ids, registrantEmail:$registrantEmail, coinTypes:$coinTypes, passedSince:$passedSince, passedBefore:$passedBefore}) {
                    id
                    currencyCode
                    type
                    coinType
                    price
                    quantity
                    fee
                    remainQuantity
                    minimumTradableQuantity
                    createdAt
                    status
                }
            }
        `,
        variables: variables,
    });
}


/**
 * 거래신청 승인하기
 * @param id
 * @param accessToken
 * @returns {query|Promise<PermissionStatus>}
 */
export function setDecideTrade(id, accessToken, approval) {
    return client.mutate({
        mutation: gql`
            mutation decideTrade($accessToken:String, $id: Int, $approval: Boolean) {
                decideTrade(accessToken:$accessToken, id:$id, approval: $approval) {
                    id
                    status
                }
            }
        `,
        variables: {
            accessToken,
            id,
            approval,
        },
    });
}

/**
 * 거래요청 취소
 * @param id
 * @param accessToken
 * @returns {*}
 */
export function requestApprovalFalse(id, accessToken) {
    return client.mutate({
        mutation: gql`
            mutation cancelTradeApplication($accessToken:String!, $id: Int!) {
                cancelTradeApplication(accessToken:$accessToken, id:$id) {
                    id
                    status
                }
            }
        `,
        variables: {
            accessToken,
            id,
        },
    });
}

/**
 * 거래등록 취소
 * @param id
 * @param email
 * @returns {*}
 */
export function cancelTradeReg(accessToken, id) {
    return client.mutate({
        mutation: gql`
            mutation cancelTradeRegistration($accessToken:String!, $id: Int!) {
                cancelTradeRegistration(accessToken:$accessToken, id:$id) {
                    id
                }
            }
        `,
        variables: {
            accessToken,
            id,
        },
    });
}

/**
 * 거래중, 거래완료 내역 가져오기
 * @param email
 * @param coinType
 * @param type
 * @param passedSince
 * @param passedBefore
 * @returns {query|Promise<PermissionStatus>}
 */
export const getTradeApplications = async (email, q = null, coinType = null, type = null, applicationStatus,
                                           passedSince = null, passedBefore = null) => {
    return client.query({
        query: gql`
            query getTradeApplications($email:String!, $q:String, $coinType: [CoinType], $type: TradeRegistrationType,
                $applicationStatus: [TradeApplicationStatus], $passedSince:ZonedDateTime, $passedBefore:ZonedDateTime) {
                findUser(email: $email) {
                    email
                    roles {
                        name
                    }
                }

                getTradeApplications(filter: {email:$email, q: $q, coinTypes: $coinType, type: $type,
                    applicationStatus: $applicationStatus, passedSince:$passedSince, passedBefore:$passedBefore }) {
                    id
                    tradeRegistration {
                        currencyCode
                        type
                        registrant {
                            email
                        }
                        coinType
                        price
                        quantity
                        remainQuantity
                        minimumTradableQuantity
                    }
                    applicant {
                        email
                    }
                    status
                    waitedAt
                    confirmedAt
                    quantity
                    saleFee
                    buyFee
                    seller {
                        email
                        roles {
                            name
                        }
                        bankAccount {
                            bankName
                            username
                            accountNumber
                        }
                    }
                    buyer {
                        email
                        roles {
                            name
                        }
                        bankAccount {
                            bankName
                            username
                            accountNumber
                        }
                    }
                    buySellerCommission
                    createdAt
                    approvalAt

                    registrantBankAccount {
                        username
                        bankName
                        accountNumber
                    }

                    applicantBankAccount {
                        username
                        bankName
                        accountNumber
                    }
                }
            }
        `,
        variables: {
            email,
            q,
            coinType,
            type,
            applicationStatus,
            passedSince,
            passedBefore,
        },
    });
}


export const downloadXls = async (email, q = null, coinType = null, type = null, applicationStatus,
                                  passedSince = null, passedBefore = null) => {
    return client.query({
        query: gql`
            query downloadXls($q:String, $email:String!, $coinType: [CoinType], $type: TradeRegistrationType,
                $applicationStatus: [TradeApplicationStatus], $passedSince:ZonedDateTime, $passedBefore:ZonedDateTime) {
                downloadXls(filter: {q:$q, email:$email, coinTypes: $coinType, type: $type,
                    applicationStatus: $applicationStatus, passedSince:$passedSince, passedBefore:$passedBefore })
            }
        `,
        variables: {
            q,
            email,
            coinType,
            type,
            applicationStatus,
            passedSince,
            passedBefore,
        },
    });
}


/**
 * 거래중, 거래완료 내역 가져오기
 * @param email
 * @param coinType
 * @param passedSince
 * @param passedBefore
 * @returns {query|Promise<PermissionStatus>}
 */
export function getOneTrade(ids) {
    return client.query({
        query: gql`
            query getTradeApplications($ids:[Int]) {
                getTradeApplications(filter: {ids:$ids}) {
                    id
                    tradeRegistration {
                        currencyCode
                        type
                        registrant {
                            email
                        }
                        coinType
                        price
                        quantity
                        remainQuantity
                        minimumTradableQuantity
                    }
                    applicant {
                        email
                    }
                    status
                    waitedAt
                    confirmedAt
                    quantity
                    createdAt
                    approvalAt
                }
            }
        `,
        variables: {
            ids,
        },
    });
}

/**
 * 입출금내역 가져오기
 * @param accessToken
 * @param coinType
 * @param passedSince
 * @param passedBefore
 * @returns {query|Promise<PermissionStatus>}
 */
export function getWalletTransactions(accessToken, coinType, type, passedSince, passedBefore) {
    return client.query({
        query: gql`
            query getWalletTransactions($accessToken:String!, $coinType:CoinType, $type: WalletTransactionType,
                $passedSince:ZonedDateTime, $passedBefore:ZonedDateTime) {
                getWalletTransactions(accessToken:$accessToken, filter:{coinType:$coinType, type: $type,
                    passedSince:$passedSince, passedBefore:$passedBefore}) {
                    id
                    type
                    status
                    address
                    quantity
                    confirmed
                    confirmedAt
                    createdAt
                    wallet{
                        coinType
                    }
                }
            }
        `,
        variables: {
            accessToken,
            coinType,
            type,
            passedSince,
            passedBefore,
        },
    });
}


/**
 * 입금 신청
 */
export const requestDeposit = (coinType, address) => {
    return client.mutate({
        mutation: gql`
            mutation requestDeposit($coinType:CoinType, $address:String) {
                requestDeposit(input: {coinType:$coinType, address:$address}) {
                    address
                    confirmed
                    confirmedAt
                    createdAt
                    id
                    quantity
                    status
                    type
                }
            }
        `,
        variables: {
            coinType,
            address,
        },
    });
}

/**
 * 출금 신청
 */
export const requestWithdraw = (accessToken, address, coinType, email, quantity, pin, withdrawalAuto, toFranchisee, link) => {
    return client.mutate({
        mutation: gql`
            mutation requestWithdraw($accessToken:String!, $address:String, $coinType:CoinType, $email:String,
                $quantity:BigDecimal, $pin:String, $withdrawalAuto: Boolean, $toFranchisee: Boolean, $link: String ) {
                requestWithdraw(input: {accessToken:$accessToken, address:$address, coinType:$coinType, email:$email,
                    quantity:$quantity, pin:$pin, withdrawalAuto:$withdrawalAuto, toFranchisee: $toFranchisee, link: $link}) {
                    address
                    confirmed
                    confirmedAt
                    createdAt
                    id
                    quantity
                    status
                    type
                }
            }
        `,
        variables: {
            accessToken,
            address,
            coinType,
            email,
            quantity,
            pin,
            withdrawalAuto,
            toFranchisee,
            link,
        },
    });
}

/**
 * 등록된 거래 목록 가져오기
 * @param coinType
 * @param currency
 * @returns {*}
 */
export function getAllTradeRegistrationPrices(coinType, currency) {
    return client.query({
        query: gql`
            query allTradeRegistrationPrices($coinType:CoinType!, $currencyCode:CurrencyCode!) {

                allTradeRegistrationPrices(coinType: $coinType, currencyCode: $currencyCode) {
                    price
                    quantity
                    remainQuantity
                    type
                    tradeRegistrations {
                        id
                        registrant {
                            email
                        }
                        coinType
                        currencyCode
                        minimumTradableQuantity
                        price
                        quantity
                        remainQuantity
                        createdAt
                    }
                }

            }
        `,
        variables: {
            coinType,
            currencyCode: currency,
        },
    });
}


/**
 * 거래 매칭하기
 * @param email
 * @param quantity
 * @param id
 * @returns {*}
 */
export function applyTrades(accessToken, email, quantity, ids, pin) {
    return client.mutate({
        mutation: gql`
            mutation applyTrades($accessToken:String!, $email:String!, $quantity:BigDecimal!, $ids:[Int]! , $pin:String) {
                applyTrades(input: {
                    accessToken:$accessToken,
                    applicantEmail:$email,
                    quantity:$quantity,
                    ids:$ids
                    pin:$pin
                }) {
                    id
                    status
                    tradeRegistration{
                        id
                        registrant{
                            email
                        }
                        price
                        coinType
                    }
                    quantity
                }
            }
        `,
        variables: {
            accessToken,
            email,
            quantity,
            ids,
            pin,
        },
    });
}

/**
 * 거래 입금 완료, 및 입금 확인
 * @param email
 * @param id
 * @returns {Promise<FetchResult<any>>}
 */
export function confirmTrade(email, id) {
    return client.mutate({
        mutation: gql`
            mutation confirmTrade($email:String!, $id:Int!) {
                confirmTrade(
                    email:$email,
                    id:$id
                ) {

                    status

                }
            }
        `,
        variables: {
            email,
            id,
        },
    });
}


/**
 * 시간 연장하기
 * @param accessToken
 * @param id
 * @param minutes
 * @returns {Promise<FetchResult<any>>}
 */
export function extendTrade(accessToken, id, minutes) {
    return client.mutate({
        mutation: gql`
            mutation extendTrade($accessToken:String!, $id:Int!, $minutes: Float) {
                extendTrade(
                    accessToken:$accessToken,
                    id:$id
                    minutes:$minutes,
                ) {
                    id
                    approvalAt
                    confirmedAt
                    waitedAt
                }
            }
        `,
        variables: {
            accessToken,
            id,
            minutes,
        },
    });
}


/**
 * 푸쉬알람
 * @param email
 * @param title
 * @param body
 * @returns {*}
 */
export function pushMessage(email, notification, data) {
    return client.mutate({
        mutation: gql`
            mutation sendNotificationByEmail($email:String!, $notification: NotificationInput, $data:NotificationData) {
                sendNotificationByEmail(email:$email, notification:$notification, data:$data) {
                    multicastId
                    success
                    failure
                    canonicalIds
                    results{
                        messageId
                        error
                    }
                }
            }
        `,
        variables: {
            email,
            notification,
            data,
        },
    });
}

export function registerCustodyNode(custodyId, email, referrerEmail) {
    return client.mutate({
        mutation: gql`
            mutation registerCustodyNode($custodyId: Int!, $email: String!, $referrerEmail: String) {
                registerCustodyNode(custodyId: $custodyId, email: $email, referrerEmail: $referrerEmail) {
                    id
                }
            }
        `,
        variables: {
            custodyId,
            email,
            referrerEmail,
        },
    });
}


export function findCustody(id) {
    return client.query({
        query: gql`
            query findCustody($id: ID!) {
                findCustody(id: $id) {
                    id
                    type
                    coinType
                }
            }
        `,
        variables: {
            id
        },
    });
}


export function findCustodyNode(custodyId, email) {
    return client.query({
        query: gql`
            query findCustodyNode($custodyId: Int!, $email: String!) {
                findCustodyNode(custodyId: $custodyId, email: $email) {
                    id
                    tradeAddress
                    certified

                    accounts {
                        type
                        balance
                    }
                }
            }
        `,
        variables: {
            custodyId,
            email,
        },
    });
}

export function getCustodyAssets(accessToken, coinType) {
    return client.query({
        query: gql`
            query getCustodyAssets($accessToken:String!, $coinType: CoinType) {
                getCustodyAssets(accessToken:$accessToken, coinType:$coinType) {
                    name
                    address
                    balance
                }
            }
        `,
        variables: {
            accessToken,
            coinType,
        },
    });
}


export function findCustodyReferrer(custodyId, email) {
    return client.query({
        query: gql`
            query findCustodyReferrer($custodyId: Int!, $email: String!) {
                findCustodyReferrer(custodyId: $custodyId, email: $email) {
                    referrer
                }
            }
        `,
        variables: {
            custodyId,
            email,
        },
    });
}


export function reinvestment(custodyId, email, type, amount) {
    return client.mutate({
        mutation: gql`
            mutation reinvestment($custodyId: Int!, $email: String!, $type: CustodyAccountType!, $amount: BigDecimal) {
                reinvestment(custodyId: $custodyId, email: $email, type: $type, amount: $amount) {
                    balance
                }
            }
        `,
        variables: {
            custodyId,
            email,
            type,
            amount,
        },
    });
}

export function allCustodyAccountHistories(email, page, perPage, sortField, sortOrder) {
    return client.query({
        query: gql`
            query allCustodyAccountHistories($email: String,
                $page: Int, $perPage: Int, $sortField: String, $sortOrder: String) {
                allCustodyAccountHistories(filter: {email: $email},
                    page: $page, perPage: $perPage, sortField: $sortField, sortOrder: $sortOrder) {
                    createdAt
                    amount
                    custodyAccount {
                        custodyNode {
                            user {
                                email
                            }
                        }
                    }

                    type
                }
            }
        `,
        variables: {
            email,
            page,
            perPage,
            sortField,
            sortOrder,
        },
    });
}

export function getCustodyROIChartData(custodyId, email) {
    return client.query({
        query: gql`
            query getCustodyROIChartData($custodyId: Int!, $email: String!) {
                getCustodyROIChartData(custodyId: $custodyId, email: $email) {
                    date
                    amount
                }
            }
        `,
        variables: {
            custodyId,
            email,
        },
    });
}


export function getTradeRegistrationsOfBuySeller(coinType, currency) {
    return client.query({
        query: gql`
            query getTradeRegistrationsOfBuySeller($coinType:CoinType!, $currencyCode:CurrencyCode!) {
                getTradeRegistrationsOfBuySeller(coinType: $coinType, currencyCode: $currencyCode) {
                    registrant {
                        email
                    }
                    price
                    quantity
                    remainQuantity
                    type
                }
            }
        `,
        variables: {
            coinType,
            currencyCode: currency,
        },
    });
}

export const getTotalExchangeInfoByCurrency = (coinTypes) => {
    return client.query({
        query: gql`
            query getTotalExchangeInfoByCurrency($coinTypes:[CoinType]!) {
                getTotalExchangeInfoByCurrency(coinTypes: $coinTypes) {
                    coinType
                    price
                    totalQuantity
                }
            }
        `,
        variables: {
            coinTypes
        },
    });
}

export function notifyToAdmin(message) {
    return client.mutate({
        mutation: gql`
            mutation notifyToAdmin($message: String) {
                notifyToAdmin(message: $message) {
                    ok
                }
            }
        `,
        variables: {
            message
        },
    });
}

export function sendSMS(email, message) {
    return client.mutate({
        mutation: gql`
            mutation sendSMS($email: String!, $message: String!) {
                sendSMS(email: $email, message: $message) {
                    message
                }
            }
        `,
        variables: {
            email,
            message
        },
    });
}


export function requestCustodyAccountWithdraw(custodyId, email, type, amount) {
    return client.mutate({
        mutation: gql`
            mutation requestCustodyAccountWithdraw($custodyId: Int, $email: String, $type: CustodyAccountType, $amount: BigDecimal) {
                requestCustodyAccountWithdraw(custodyId: $custodyId, email: $email, type: $type, amount: $amount) {
                    amount
                }
            }
        `,
        variables: {
            custodyId, email, type, amount
        },
    });
}

export function getLockedQuantity(accessToken, walletType, coinType) {
    return client.query({
        query: gql`
            query getLockedQuantity($accessToken: String!, $walletType: WalletType!, $coinType: CoinType!) {
                getLockedQuantity(accessToken: $accessToken, walletType: $walletType, coinType: $coinType)
            }
        `,
        variables: {
            accessToken,
            walletType,
            coinType,
        },
    });
}


export const getUserByAccessToken = (accessToken) => {
    return client.query({
        query: gql`
            query getUserByAccessToken($accessToken: String!) {
                getUserByAccessToken(accessToken: $accessToken) {
                    email
                    phoneNumber
                    withdrawalSettings {
                        coinType
                        auto
                        address
                    }
                }
            }
        `,
        variables: {
            accessToken
        },
    });
}

export const getCoinMarket = (coinType) => {
    return client.query({
        query: gql`
            query getCoinMarket($coinType: CoinType!) {
                getCoinMarket(coinType: $coinType) {
                    coinType
                    fee {
                        coinType
                        sale
                        buy
                    }
                }
            }
        `,
        variables: {
            coinType
        },
    });
}

export const getWithdrawalAddress = (coinType) => {
    return client.query({
        query: gql`
            query getWithdrawalAddress($coinType: CoinType!) {
                getWithdrawalAddress(coinType: $coinType)
            }
        `,
        variables: {
            coinType
        },
    });
}


export const getChildNodeUsers = (custodyId, email) => {
    return client.query({
        query: gql`
            query getChildNodeUsers($custodyId: Int!, $email: String) {
                getChildNodeUsers(custodyId: $custodyId, email: $email) {
                    id
                    user {
                        email
                    }
                    createdAt
                }
            }
        `,
        variables: {
            custodyId,
            email,
        },
    });
}

export const signIn = (email, password, recaptchaToken, type, token, phoneNumber) => {
    localStorage.removeItem('token');

    email = email.toLowerCase();

    return client.mutate({
        mutation: gql`
            mutation signIn($email: String!, $password: String!, $recaptchaToken: String!,
                $type: DeviceTokenType, $token: String, $phoneNumber: String) {
                signIn(email: $email, password: $password, recaptchaToken: $recaptchaToken) {
                    email
                    token
                }

                registerDeviceToken(email: $email, type: $type, token: $token, phoneNumber: $phoneNumber) {
                    token
                    type
                    createdAt
                }
            }
        `,
        variables: {
            email,
            password,
            recaptchaToken,
            type,
            token,
            phoneNumber
        },
    }).then((response) => {
        if (response.data.signIn.token) {
            localStorage.setItem('token', response.data.signIn.token);
        }

        return Promise.resolve(response);
    }).catch((error) => {
        console.dir(error);

        return Promise.reject(error);
    })
}


export const changePassword = (password, newPassword) => {
    return client.mutate({
        mutation: gql`
            mutation changePassword($password: String!, $newPassword: String!) {
                changePassword(password: $password, newPassword: $newPassword) {
                    email
                    roles {
                        name
                    }
                }
            }
        `,
        variables: {
            password,
            newPassword,
        },
    });
}

export const verifyPinNumber = (number) => {
    return client.query({
        query: gql`
            query verifyPinNumber($number: String!) {
                verifyPinNumber(number: $number) {
                    result
                }
            }
        `,
        variables: {
            number
        },
    });
}


export const changePinNumber = (number, newNumber) => {
    return client.mutate({
        mutation: gql`
            mutation changePinNumber($number: String!, $newNumber: String!) {
                changePinNumber(number: $number, newNumber: $newNumber) {
                    result
                }
            }
        `,
        variables: {
            number,
            newNumber,
        },
    });
}


export const requestResetAuth = (email, type) => {
    return client.mutate({
        mutation: gql`
            mutation requestResetAuth($email: String!, $type: ResetAuthType!) {
                requestResetAuth(email: $email, type: $type) {
                    result
                }
            }
        `,
        variables: {
            email,
            type,
        },
    });
}

export const saveWithdrawal = (accessToken, email, coinType, address, withdrawalAuto) => {
    return client.mutate({
        mutation: gql`
            mutation saveWithdrawal($accessToken:String, $email:String, $coinType:CoinType, $address:String, $withdrawalAuto: Boolean) {
                saveWithdrawal(input: {accessToken:$accessToken, email:$email, coinType:$coinType, address:$address,
                    withdrawalAuto:$withdrawalAuto}) {
                    coinType
                    address
                    createdAt
                    id
                }
            }
        `,
        variables: {
            accessToken,
            email,
            coinType,
            address,
            withdrawalAuto,
        },
    });
}

export const voteForSwap = (value) => {
    return client.mutate({
        mutation: gql`
            mutation voteForSwap($value:String) {
                voteForSwap(value: $value)
            }
        `,
        variables: {
            value,
        },
    });
}

export const getVoteResult = () => {
    return client.query({
        query: gql`
            query getVoteResult {
                getVoteResult {
                    totalYes
                    totalNo
                }
            }
        `
    });
}

export const allCurrencies = async () => {
    return await client.query({
        query: gql`
            query allCurrencies {
                allCurrencies(sortField: "id", sortOrder: "DESC") {
                    id
                    symbol
                    contract
                    siteUrl
                    explorerUrl
                }
            }
        `
    });
}

export const getNotReceivedPosts = async () => {
    return await client.query({
        query: gql`
            query getNotReceivedPosts {
                getNotReceivedPosts {
                    id
                }
            }
        `
    });
}

export const modifyAllReceivedPosts = async () => {
    return await client.mutate({
        mutation: gql`
            mutation modifyAllReceivedPosts {
                modifyAllReceivedPosts {
                    id
                }
            }
        `
    });
}

export const getTotalTradeApplicationInfo = async (email, q = null, coinType = null, type = null, applicationStatus,
                                           passedSince = null, passedBefore = null) => {
    return client.query({
        query: gql`
            query getTotalTradeApplicationInfo($q:String, $email:String!, $coinType: [CoinType], $type: TradeRegistrationType,
                $applicationStatus: [TradeApplicationStatus], $passedSince:ZonedDateTime, $passedBefore:ZonedDateTime) {
                getTotalTradeApplicationInfo(filter: {q: $q, email:$email, coinTypes: $coinType, type: $type,
                    applicationStatus: $applicationStatus, passedSince:$passedSince, passedBefore:$passedBefore }) {
                    symbol
                    quantity
                    price
                    fee
                    commission
                }
            }
        `,
        variables: {
            email,
            q,
            coinType,
            type,
            applicationStatus,
            passedSince,
            passedBefore,
        },
    });
}
