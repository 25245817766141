import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
// import moment from 'moment';
import { ApolloProvider, Query } from 'react-apollo';
import { ALL_CUSTODY_ACCOUNT_HISTORIES } from './lib/query';
import { getEmail } from './lib/otex-common';
import client from './lib/apolloClient';

class CustodyAccountHistory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: null,
        };
    }

    render() {
        const { email } = this.props;

        return (
            <ApolloProvider client={client}>
                <Query
                    query={ALL_CUSTODY_ACCOUNT_HISTORIES}
                    variables={{ email }}
                >
                    {({ loading, error, data }) => {
                        if (loading) return <tr><td colSpan={10}>Loading...</td></tr>;
                        if (error) return <tr><td colSpan={10}>Error!</td></tr>;
                        return  (
                            <Fragment>
                            {data['allCustodyAccountHistories'].map(({
                                    id, custodyAccount, type, amount, createdAt,
                                }) => (
                                  <tr key={id}>
                                      <td><p className="date">{createdAt}</p></td>
                                      <td>{custodyAccount['custodyNode']['custody'].type}</td>
                                      <td>{type}</td>
                                      <td>{amount}</td>
                                    </tr>
                                ))}
                          </Fragment>
                        );
                    }}
              </Query>
          </ApolloProvider>
        );
    }
}

ReactDOM.render(<CustodyAccountHistory email={getEmail()} />,
    document.getElementById('custody-account-history'));
