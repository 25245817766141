import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {ApolloProvider, Query} from 'react-apollo';
import {GET_TRADE_REGISTRATIONS_OF_BUY_SELLER} from './lib/query';
import {getAccessToken, getCurrentCoin, getCurrentCurrency, getEmail, transCurrentCoin} from './lib/otex-common';
import client from './lib/apolloClient';
import {__} from './i18nConfig';
import {getAllTradeRegistrationPrices, getLockedQuantity, getUserWallets} from "./lib/otex-api";
import BigNumber from "bignumber.js";
import {parse} from 'query-string';
import {coinToCurrency, convertCommonUnit, getBaseTransactionFeeRate, getCoinTypeView} from './export-common'
import {alertPop} from "./lib/common";

const BuySeller = (props) => {
    const {coinType, currencyCode, accessToken, email} = props;
    const [mode, setMode] = useState(props.mode)
    const [current, setCurrent] = useState({
        id: 0,
        type: (mode === 'BUY') ? 'SELL' : 'BUY',
        price: '',
        quantity: 0,
        minimumTradableQuantity: 0
    });
    const [quantity, setQuantity] = useState('');
    const [total, setTotal] = useState('');
    const [balance, setBalance] = useState(0);
    const [tradableQuantity, setTradableQuantity] = useState(0);
    const [refreshSec, setRefreshSec] = useState(0);

    const getBalance = async () => {
        const res = await getUserWallets(accessToken, email);
        const currentWallet = res.data['getWallets'].filter((item) => item.coinType === getCurrentCoin())[0];
        const {data} = await getLockedQuantity(accessToken, 'OTEX', getCurrentCoin())

        if (currentWallet != null) {
            setBalance(currentWallet['balance']);
            setTradableQuantity((currentWallet['balance'] - data.getLockedQuantity < 0) ? 0 : currentWallet['balance'] - data.getLockedQuantity);
        }
    }

    getBalance();

    const maximum = () => {
        calculateByQuantity(current.quantity);
    }

    const calculateByQuantity = (quantity) => {
        setQuantity(quantity);
        quantity = quantity.replace(',', '');
        let t = new BigNumber(isNaN(current.price) ? 0 : Number(current.price))
            .times(isNaN(quantity) ? 0 : Number(quantity)).toFormat()
        setTotal(convertCommonUnit(t));
    }

    const calculateByTotal = (total) => {
        setTotal(total);
        total = total.replace(',', '');
        let q = new BigNumber(total).div(current.price);

        if(isNaN(q)) {
            q = 0;
        }

        setQuantity(convertCommonUnit(q));
    }

    const reset = () => {
        setQuantity('');
        setTotal('');
    }

    const confirm = () => {
        if (!(0 < quantity)) {
            alertPop("icon-check", __('구매 수량을 입력해주세요'));
            return;
        }

        if (current.id === 0) {
            alertPop("icon-check", __('거래를 선택해 주세요'));
            return;
        }

        if (current.quantity < quantity) {
            alertPop("icon-check", __('최대 판매 수량을 넘었습니다. 다시 입력해 주세요'));
            return;
        }

        const ids = [current.id];
        const link = current.type === 'BUY' ? 'sell' : 'buy';
        sessionStorage.setItem('otex_select_trade_ids', ids.toString());
        location.href = `/trade/${link}_info?amount=${quantity}&price=${current.price}`;
    };

    useEffect(() => {
    });

    return (
        <ApolloProvider client={client}>
            <div className="innerWrap">
                <div className="head">
                    <div className="btnArea left">
                        <button type="button" className="buy" onClick={e => setMode('BUY')} title={__('매수')}>
                            <span>{__('구매')}<br/>{__('물량')}</span>
                        </button>
                        <button type="button" className="sell" onClick={e => setMode('SELL')} title={__('매도')}>
                            <span>{__('판매')}<br/>{__('물량')}</span>
                        </button>
                        <select style={{border: '1px solid black',
                            marginLeft: '5px'
                        }} value={refreshSec} onChange={(e) => setRefreshSec(e.target.value)}>
                            <option value="0">자동새로고침</option>
                            <option value="20">20초</option>
                            <option value="50">50초</option>
                            <option value="60">1분</option>
                            <option value="120">2분</option>
                            <option value="180">3분</option>
                            <option value="300">5분</option>
                            <option value="600">10분</option>
                        </select>
                    </div>

                    <div className="btnArea right">
                        {__('바이셀러거래')}
                    </div>
                </div>

                <div className="body">
                    <div className="label">
                        <p>{__('ID')}</p>
                        <p>{__('가격')}</p>
                        <p>{__('수량')}</p>
                    </div>
                    <Query
                        query={GET_TRADE_REGISTRATIONS_OF_BUY_SELLER}
                        variables={{coinType, currencyCode}}
                        pollInterval={refreshSec * 1000}>
                        {({loading, error, data}) => {
                            if (loading) return <div className="priceList">Loading...</div>;
                            if (error) return <div className="priceList">Error!</div>;

                            return (
                                <div className="buySellerList">
                                    {data['getTradeRegistrationsOfBuySeller']
                                        .filter(it => it.type !== mode)
                                        .map(({
                                                  id, registrant, quantity, price, type, minimumTradableQuantity
                                              }) => (
                                            <button key={id} type="button" className={"priceBtn " +
                                            (type === 'BUY' ? 'buy' : 'sell') + (id === current.id ? ' selected' : '')}
                                                    onClick={() => {
                                                        setQuantity('');
                                                        setCurrent({
                                                            id, type, price, quantity, minimumTradableQuantity
                                                        });
                                                    }}>
                                                <span className="email">{registrant['email'].split('@')[0]}</span>
                                                <span
                                                    className="price">{convertCommonUnit(price)}</span>
                                                <span
                                                    className="amount"><b>{convertCommonUnit(quantity)}</b></span>
                                            </button>
                                        ))}
                                </div>
                            );
                        }}
                    </Query>
                    <div className="applyArea">

                        <div className={"box buy " + (mode === 'BUY' ? 'open' : '')}>
                            <h2>{__('매수 주문량')}</h2>
                            <p id="balance"
                               className="para right tiny bold">{__('잔액')} : {convertCommonUnit(balance)}</p>
                            <p id="balance"
                               className="para right tiny bold marginB">{__('거래가능')} : {convertCommonUnit(tradableQuantity)}</p>
                            <div className="para right">
                                <button type="button" className="maxBtn" onClick={() => maximum()}>{__('최대')}</button>
                            </div>
                            <label>
                                <input type="text" className="applyAmount" id="buy_request_amount"
                                       placeholder={__('구매수량')}
                                       value={quantity} onChange={e => calculateByQuantity(e.target.value)}/>
                                <span className="select_coin">{getCoinTypeView(coinType)}</span>
                            </label>
                            <label>
                                <input type="text" className="digitNumber priceInput value" id="buy_request_price"
                                       placeholder={__('구매가격')} readOnly
                                       value={0 < current.price ? convertCommonUnit(current.price) : ''}/>
                                <span className="select_currency">{currencyCode}</span>
                            </label>
                            <label>
                                <input type="text" id="buy_request_total"
                                       placeholder={__('총 주문금액')}
                                       onChange={e => calculateByTotal(e.target.value)} value={total}/>
                                <span className="select_currency">{currencyCode}</span>
                            </label>
                            <p className="para tiny right" style={{'fontSize': '0.7em', 'marginTop': '0.5em'}}>
                                <span>{__('최소주문수량')}&nbsp;</span>
                                <span>{current.minimumTradableQuantity}</span>
                            </p>

                            <div className="btnArea around">
                                <button type="button" className="btn fill gray w45"
                                        onClick={() => reset()}>{__('초기화')}</button>
                                <button type="button" className="btn fill red w45" onClick={() => confirm()}
                                        id="buy_request_btn">{__('매수')}</button>
                            </div>

                            <div className="popLayer">
                                <div className="pop">
                                    <p className="title">{__('매수 등록 확인')}</p>
                                    <p className="para between small marginT">
                                        <span className="blue">{__('구매가격')}</span>
                                        <span><span id="buy_register_price"/>&nbsp;<span
                                            className="select_currency">KRW</span></span>
                                    </p>
                                    <p className="para between small marginT">
                                        <span className="blue">{__('구매수량')}</span>
                                        <span><span id="buy_register_amount"/>&nbsp;<span
                                            className="select_coin">BTC</span></span>
                                    </p>
                                    <p className="para between small marginT">
                                        <span className="blue">{__('최소거래수량')}</span>
                                        <span><span id="buy_register_min"/>&nbsp;<span
                                            className="select_coin">BTC</span></span>
                                    </p>
                                    <p className="para between small marginT">
                                        <span className="blue">{__('총 주문금액')}</span>
                                        <span><span id="buy_register_total"/>&nbsp;<span
                                            className="select_currency">KRW</span></span>
                                    </p>
                                    <p className="para between small marginT">
                                        <span className="blue">{__('거래수수료')}</span>
                                        <span><span id="buy_register_fee"/>&nbsp;<span
                                            className="select_coin">BTC</span><br/><span
                                            id="buy_register_fee_krw">111</span>&nbsp;<span
                                            className="select_currency">KRW</span></span>
                                    </p>
                                    <p className="para tiny marginT">{__('등록된 거래가 완료될 경우 수수료를 제외한 수량이 입금됩니다.')}</p>
                                    <label className="checkLabel"><input type="checkbox"
                                                                         className="confirm"/> {__('내용을 확인하였습니다.')}
                                    </label>
                                    <div className="btnArea around">
                                        <button type="button" className="btn fill gray w45"
                                        >{__('취소')}</button>
                                        <button type="button" className="btn fill blue w45"
                                        >{__('확인')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className={"box sell " + (mode === 'SELL' ? 'open' : '')}>
                            <h2>{__('매도 주문량')}</h2>
                            <p id="balance"
                               className="para right tiny bold">{__('잔액')} : {convertCommonUnit(balance)}</p>
                            <p id="balance"
                               className="para right tiny bold marginB">{__('거래가능')} : {convertCommonUnit(tradableQuantity)}</p>
                            <p className="para right tiny">{__('가능한도')}</p>
                            <p className="para right tiny sell_my_balanceCoinType">{balance} {getCoinTypeView(coinType)}</p>
                            <p className="para right tiny marginB sell_my_balancePriceCurrency"
                               style={{fontSize: '0.5em'}}>
                                {coinToCurrency(getCurrentCoin(), getCurrentCurrency(), tradableQuantity)} {currencyCode}
                            </p>
                            <div className="para right">
                                <button type="button" className="maxBtn" onClick={() => maximum()}>{__('최대')}</button>
                            </div>
                            <label>
                                <input type="text" id="sell_request_amount" className="applyAmount"
                                       placeholder={__('판매수량')}
                                       value={quantity} onChange={e => calculateByQuantity(e.target.value)}/>
                                <span className="select_coin">{getCoinTypeView(coinType)}</span>
                            </label>
                            <label>
                                <input type="text" id="sell_request_price" className="digitNumber priceInput value"
                                       placeholder={__('판매가격')} readOnly
                                       value={0 < current.price ? convertCommonUnit(current.price) : ''}/>
                                <span className="select_currency">{currencyCode}</span>
                            </label>
                            <label>
                                <input type="text" id="sell_request_total"
                                       placeholder={__('총 주문금액')} onChange={e => calculateByTotal(e.target.value)}
                                       value={total}/>
                                <span className="select_currency">{currencyCode}</span>
                            </label>
                            <p className="para tiny right" style={{'fontSize': '0.7em', 'marginTop': '0.5em'}}>
                                {__('거래수수료')}&nbsp;
                                <span id="sell_request_fee">{
                                    new BigNumber(isNaN(current.price * quantity) ? 0
                                        : Number(quantity)).times(getBaseTransactionFeeRate()).toFormat()
                                }</span>&nbsp;
                                <span className="select_coin">{getCoinTypeView(coinType)}</span>
                            </p>
                            <p className="para tiny right" style={{'fontSize': '0.7em', 'marginTop': '0.5em'}}>
                                <span>{__('최소주문수량')}&nbsp;</span>
                                <span>{current.minimumTradableQuantity}</span>
                            </p>


                            <div className="traderList">
                                <p className="para small lineT">{__('매수자 리스트')}</p>
                                <div className="traderTableWrap">
                                    <table className="traderTable">
                                        <tbody className="traderListView">

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="btnArea around">
                                <button type="button" className="btn fill gray w45" onClick={() => reset()}>
                                    {__('초기화')}</button>
                                <button type="button" className="btn fill blue w45" onClick={() => confirm()}>
                                    {__('매도')}</button>
                            </div>

                            <div className="popLayer">
                                <div className="pop">
                                    <p className="title">{__('매도 등록 확인')}</p>
                                    <p className="para between small marginT">
                                        <span className="blue">{__('판매가격')}</span>
                                        <span><span id="sell_register_price"/>&nbsp; <span
                                            className="select_currency">KRW</span></span>
                                    </p>
                                    <p className="para between small marginT">
                                        <span className="blue">{__('판매수량')}</span>
                                        <span><span id="sell_register_amount"/>&nbsp; <span
                                            className="select_coin">BTC</span></span>
                                    </p>
                                    <p className="para between small marginT">
                                        <span className="blue">{__('최소거래수량')}</span>
                                        <span><span id="sell_register_min">9</span>&nbsp; <span
                                            className="select_coin">BTC</span></span>
                                    </p>
                                    <p className="para between small marginT">
                                        <span className="blue">{__('총 주문금액')}</span>
                                        <span><span id="sell_register_total"/>&nbsp; <span
                                            className="select_currency">KRW</span></span>
                                    </p>
                                    <p className="para between small marginT">
                                        <span className="blue">{__('거래수수료')}</span>
                                        <span><span id="sell_register_fee"/>&nbsp;<span
                                            className="select_coin">BTC</span><br/><span
                                            id="sell_register_fee_krw">111</span>&nbsp;<span
                                            className="select_currency">KRW</span></span>
                                    </p>
                                    <p className="para tiny marginT">{__('등록된 거래가 취소될 경우 수수료 차감 없이 돌려받으실 수 있습니다.')}</p>
                                    <label className="checkLabel"><input type="checkbox"
                                                                         className="confirm"/> {__('내용을 확인하였습니다.')}
                                    </label>
                                    <div className="btnArea around">
                                        <button type="button" className="btn fill gray w45"
                                        >{__('취소')}</button>
                                        <button type="button" className="btn fill blue w45"
                                        >{__('확인')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </ApolloProvider>
    );
}

const params = parse(document.location.search);
let coinType = transCurrentCoin(getCurrentCoin());
ReactDOM.render(<BuySeller mode={params.mode} coinType={coinType} currencyCode={getCurrentCurrency()}
                           accessToken={getAccessToken()} email={getEmail()}/>,
    document.getElementById('buyseller-container'));

getAllTradeRegistrationPrices(coinType, getCurrentCurrency()).then(function (res) {
    sessionStorage.setItem('otex_trade_list', JSON.stringify(res.data['allTradeRegistrationPrices']));
});

