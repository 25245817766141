import axios from 'axios';
import jwtDecoder from 'jwt-decode';

/**
 * API 서버에 로그인해서 access_token 을 받는다.
 *
 * @param email 이메일
 * @param password 패스워드
 * @returns {*}
 */
export function login(email, password) {
    return axios.post('https://api.aiser.io/auths/auth/login/token', {
        email: email,
        password: password,
        scope: 'BANK',
    });
}

export function loginInfoParse(params) {
    return axios.post('/jwt-parse', {
        accessToken: params,
    });
}


export const validateJwt = (accessToken) => {
    try {
        let decoded = jwtDecoder(accessToken);
        if (decoded.exp <= Date.now() / 1000) {
            return false;
        }
    } catch(e) {
        return false;
    }

    return true;
}


/**
 * 법정화폐의 환율과 코인별 시세 정보를 받아옵니다.
 *
 * @returns {request.Request|IDBRequest<IDBValidKey>|Promise<void>}
 */
export const getCurrency = () => {
    return axios.put('https://kaisermisc.otexm.com/currency');
}


/**
 * 시세가 계산된 지갑 불러오기
 * @param token
 * @param email
 * @param currency
 * @returns {Promise<{totalMoney: number, wallets: []}>}
 */
export async function getPriceWallets(token, email, currency) {

    // 최종 반환 오브젝트
    let resultObj = {
        'totalMoney': 0,
        'wallets': [],
        'price': {},
        'coinName': []
    };
    // 지갑 정보 가져오기
    await getUserWallets(token, email).then(function(res) {
        resultObj.wallets = res.data.getWallets;
    })
    // 일반 시세 가져오기
    const res = await getCurrency();
    const coins = res.data.data.coins;
    const exchangeRates = res.data.data.currency;
    const exchangeRate = exchangeRates[currency];

    // KISC 시세 가져오기
    var currencyData = JSON.parse(sessionStorage.getItem('otex_currencyData'));
    if(currencyData) {
        var totalMoney = 0;
        // 지갑별로 코인 가격 처리
        resultObj.wallets.forEach(function(thisWallet) {
            let coinName = thisWallet.coinType;
            const coin = coins.find(coin => coin.symbol === coinName);

            if(coin) {
                resultObj.price[coinName] = (Math.round((coin.price * exchangeRate) * 100) / 100);
            } else {
                resultObj.price[coinName] = 0;
            }

            resultObj.coinName.push(coinName);
            // 총 보유자산
            totalMoney += resultObj.price[coinName] * thisWallet.balance;
        });
        resultObj.totalMoney = totalMoney;
        return resultObj;
    } else {
        alertPop('icon-error', '통화정보를 읽어오지 못했습니다. 메인페이지로 돌아갑니다.', function() {
            location.href = '/main';
        });
    }
}

/**
 * captcha
 * @returns {*}
 */
export function getCAPTCHA() {
    return axios.get("/svg_captcha");
}



/**
 * 인증번호 요청
 * @param number 전화번호
 * @returns {*}
 */
export function requestAuthNumber(number) {
    return axios.post('https://api.aiser.io/sms/request', {
        number: number,
    }, {
        headers: { 'Content-Type': 'application/json' },
    });

}

/**
 * 인증번호 확인
 * @param obj 인증을 위한 객체
 * @returns {*}
 */
export function checkAuthNumber(obj) {
    return axios.put('https://api.aiser.io/sms/authenticate', {
        number: obj.phone,
        token: obj.token,
        code: obj.code,
    }, {
        headers: { 'Content-Type': 'application/json' },
    });
}


